import React from 'react'
import SEO from "../../components/Seo"
import Layout from '../../components/Layout'

import Intro from '../../components/Pages/Pricing/Intro'
import Tiers from '../../components/Pages/Pricing/Tiers'
import Compare from '../../components/Pages/Pricing/Compare'
import BottomBlock from '../../components/BottomBlock'

import './pricing.scss'

export default ({location}) =>
    <Layout className='page pricingPage'>
        <SEO 
            title="LicenseSpring: Pricing" 
            description="We offer 3 account types: free, standard, and enterprise. Compare the different accounts and choose the one that's right for your organization."
            path={location.pathname}
        />
        <div className="container">
            <Intro />
            <Tiers />
            <Compare />
        </div>
        <BottomBlock />
    </Layout>