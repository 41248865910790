import React from 'react'

import './intro.scss'

export default () =>
    <section className="pricingPage__intro col__md-50">
        <h1>
            LicenseSpring <br />Pricing
        </h1>
        <p>
            Start protecting your software and enhancing customer experiences <br />by getting started with LicenseSpring today! We offer 3 plans:
        </p>
    </section>