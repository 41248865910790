import React from "react"

import Tooltip from "../../../Tooltip"

import Button from "../../../Button"
import Checkmark from "../../../../assets/img/checkmark.svg"
import GreyCross from "../../../../assets/img/greyCross.svg"
import ToCheckout from "../../../../assets/img/pricing/2checkout.svg"
import Paypal from "../../../../assets/img/pricing/paypal.svg"
import Stripe from "../../../../assets/img/pricing/stripe.svg"
import FastSpring from "../../../../assets/img/pricing/fastspring.png"

import "./compare.scss"

export default () => {
  const [blockIndex, setBlockIndex] = React.useState([])
  const toggleBlock = (index) => {
    blockIndex.indexOf(index) > -1
      ? setBlockIndex(blockIndex.filter((old) => old !== index))
      : setBlockIndex(blockIndex.concat(index))
  }

  const data = Data

  return (
    <section className="pricingPage__compare row" id="plans">
      {data.map((block, index) => {
        return (
          <div
            key={block.headline + index}
            className={`table__block row  ${
              blockIndex.indexOf(index) > -1 ? "table__block--open" : ""
            } ${block.pricing ? "table__block--pricing" : ""}`}
          >
            <div
              className="table__row table__row--head"
              onClick={() => toggleBlock(index)}
            >
              <h2>
                <span>{block.headline && block.headline}</span>
              </h2>
              {block.cells ? (
                block.cells.map((cell, index) => (
                  <div key={cell + index}>{cell}</div>
                ))
              ) : (
                <>
                  <div></div>
                  <div></div>
                  <div></div>
                </>
              )}
            </div>
            {block.rows.map((row, index) => {
              return (
                <div
                  className="table__row"
                  key={row.title && row.title + index}
                >
                  {row.title && row.title.includes("<img") ? (
                    <div dangerouslySetInnerHTML={{ __html: row.title }} />
                  ) : (
                    <h3>
                      {row.title}
                      {row.tooltip && (
                        <Tooltip
                          placement="bottom"
                          content={<span>{row.tooltip}</span>}
                        />
                      )}
                    </h3>
                  )}

                  {row.cells &&
                    row.cells.map((cell, index) =>
                      typeof cell === "string" ? (
                        <p key={cell + index}>{cell}</p>
                      ) : (
                        <div key={cell + index}>
                          {
                            <img
                              src={cell ? Checkmark : GreyCross}
                              alt={`${cell ? "Checkmark" : "GreyCross"} icon`}
                            />
                          }
                        </div>
                      )
                    )}
                </div>
              )
            })}
          </div>
        )
      })}
      <div className="table__block table__block--last row">
        <div className="table__row">
          <div></div>
          <Button to="/get-started/" color="blue" label="Start with free" />
          <Button to="/get-started/" color="blue" label="Open trial account" />
          <Button to="/get-started/" color="blue" label="Contact Us" />
        </div>
      </div>
    </section>
  )
}
const Data = [
  {
    headline: "Usage caps",
    cells: ["Free", "Standard", "Enterprise"],
    rows: [
      {
        title: "API Requests",
        tooltip:
          "An API request is defined as follows: any GET/POST/PUT/PATCH invocation where the API server responds with any status other than 5xx counts as one (1) request.",
        cells: ["5,000", "100,000", "Unlimited"],
      },
      {
        title: "Products",
        tooltip:
          "A product is the application that is being licensed. A product can be made available under several development environments and can include unlimited versions / releases",
        cells: ["3", "50", "Unlimited"],
      },
      {
        title: "Development Environments",
        tooltip:
          "A development environment is considered based on the SDK being used. If you are using our APIs / Webhooks, that is considered a single environment regardless of the implementation",
        cells: ["1", "All", "All"],
      },
    ],
  },
  {
    headline: "License Assigment",

    rows: [
      {
        title: "Node Locking",
        tooltip:
          "By default, we associate a license with a specific machine, based on our proprietary Device Fingerprinting algorithms, which can be overridden if you choose. You can specify within the platform how many machines a license can be associated with.",
        cells: [true, true, true],
      },
      {
        title: "Key-Based",
        tooltip: "Issue License Keys to your end-users",
        cells: [true, true, true],
      },
      {
        title: "User-Based",
        tooltip: "Associate a license to a user's email and issue passwords",
        cells: [false, true, true],
      },
      {
        title: "Custom Authentication",
        tooltip:
          "ERP, Session tokens, hardware tokens and other authentication mechanisms",
        cells: [false, false, true],
      },
    ],
  },
  {
    headline: "License Check",

    rows: [
      {
        title: "Online - Cloud",
        tooltip:
          "Check the validity of a license with the license status maintained on our servers",
        cells: [true, true, true],
      },
      {
        title: "Offline - Local License File Check",
        tooltip:
          "If an internet connection is unavailable, our SDKs will fallback on an encrypted local license file which is updated with the latest status received from our servers. You can set a grace period requiring an online check after a certain period of time. Our SDKs also include an anti-clock tampering mechanism",
        cells: [false, true, true],
      },
      {
        title: "Offline License Check",
        tooltip:
          "Some machines sometimes do not have access to the internet. You can use our offline activation / deactivation portals to service these customers",
        cells: [false, true, true],
      },
    ],
  },

  {
    headline: "License Models",

    rows: [
      {
        title: "Trial",
        tooltip:
          "Set a number of days that the trial period should run until it expires.",
        cells: [true, true, true],
      },
      {
        title: "Perpetual",
        tooltip:
          "Issue and assign perpetual licenses to allow your application to run forever.",
        cells: [true, true, true],
      },
      {
        title: "Time Limited",
        tooltip:
          "An issued license expires either on a specific date, or after a certain number of days after being activated",
        cells: [true, true, true],
      },
      {
        title: "Subscription",
        tooltip:
          "Sync the status of your license with your recurring payment solution. You can configure this license model in two ways: The license can remain active until it receives an instruction that the subscription is no longer valid. Alternatively, a subscription can be set to expire by default unless it receives an instructions that the subscription is valid for the next subscription cycle.",
        cells: [false, true, true],
      },
      {
        title: "Floating - Cloud",
        tooltip:
          "Allow licenses to be shared among a group of computers, and set how many of these machines can use your application at the same time.",
        cells: [false, false, true],
      },
      {
        title: "Floating On-Premises Server",
        tooltip:
          "Configure a license server on-premise to assign and revoke a pool of licenses. Control the server from the LicenseSpring cloud",
        cells: [false, false, true],
      },
      {
        title: "Consumption-Based",
        tooltip:
          "License your application on a pay-per-use model, and handle overages",
        cells: [false, false, true],
      },
    ],
  },
  {
    headline: "Licensing and Implementation Extras",

    rows: [
      {
        title: "Feature-Licensing, Activation",
        tooltip:
          "Instead of issuing separate licenses for plugins, DLCs, and other types of expansion packs, use our feature-based licensing to reduce complexity in managing multiple configurations of your applications",
        cells: [false, "5", "Unlimited"],
      },
      {
        title: "Feature-Licensing, Consumption",
        tooltip:
          "Instead of issuing separate licenses for plugins, DLCs, and other types of expansion packs, use our feature-based licensing to reduce complexity in managing multiple configurations of your applications",
        cells: [false, false, "Unlimited"],
      },
      // {
      //     title: "In-app dialogue screen",
      //     tooltip: "Reduce development time by using our activation window within your application.",
      //     cells: [
      //         "Branded",
      //         true,
      //         true,
      //     ]
      // },
      {
        title: "Custom Fields Server-to-Client",
        tooltip:
          "Create fields on the LicenseSpring Platform and push the information into your application. For example, you can send information about product updates, or maintain up to date contact information of the end-user's account manager",
        cells: [false, "1", "Unlimited"],
      },
      {
        title: "Device Custom Variables",
        tooltip:
          "Collect data from within your application and send it back to the LicenseSpring cloud, such as fields from a customer registration form, error code, or system information that our SDK is not collecting by default",
        cells: [false, false, true],
      },
      {
        title: "VM / Container Detection and Management",
        tooltip:
          "Allow or restrict usage of your licensed software running on a virtual machine",
        cells: [false, false, true],
      },
      {
        title: "Hardware Token",
        tooltip:
          "Issue hardware tokens to your end-users such as a YubiKey and require it in order for your application to run",
        cells: [false, false, true],
      },
    ],
  },
  {
    headline: "Deployment Platform",

    rows: [
      {
        title: "Maintenance Periods",
        tooltip:
          "Use this flag to determine whether the end user's license is elligible to be upgraded to a newer version of your application",
        cells: [false, true, true],
      },
      {
        title: "Product Upgrades",
        tooltip: "Upgrade from one product to another",
        cells: [false, true, true],
      },
      {
        title: "Product Versioning",
        tooltip:
          "List major and minor versions of your software, along with their release date.",
        cells: [false, true, true],
      },
    ],
  },
  {
    headline: "Portals & Integrations",

    rows: [
      {
        title: `<img src=${FastSpring} alt="FastSpring logo" /> `,
        cells: [true, true, true],
      },
      {
        title: `<img src=${Paypal} alt="PayPal logo" /> `,
        cells: [true, true, true],
      },
      {
        title: `<img src=${ToCheckout} alt="ToCheckout logo" /> `,
        cells: [true, true, true],
      },
      {
        title: `<img src=${Stripe} alt="Stripe logo" />`,
        cells: [true, true, true],
      },
      {
        title: "End-User self-serve Portal",
        tooltip:
          "Reduce customer support requests by allowing your users to transfer and reset their own licenses",
        cells: [false, "Branded", true],
      },
      {
        title: "End-User Offline Portal",
        tooltip:
          "Activate and deactivate licenses and lock them to a machine that does not have an active internet connection",
        cells: [false, true, true],
      },
      {
        title: "End-User White Label APIs",
        tooltip:
          "Host the self-serve and the white-label portal. We can also white label the API calls so that they look like they're coming from your company",
        cells: [false, true, true],
      },
      {
        title: "3rd party integrations With Zapier",
        tooltip: "Integrate with your favorite CRM and marketing platforms",
        cells: [false, false, true],
      },
      {
        title: "Affiliate and reseller Portals",
        tooltip: "Define, control, and monitor secondary distribution channels",
        cells: [false, false, true],
      },
    ],
  },
  {
    headline: "Reporting",

    rows: [
      {
        title: "Data Export",
        tooltip:
          "Export license, order, and customer information in csv format",
        cells: [false, true, true],
      },
      {
        title: "License and product usage reports",
        tooltip: "Monitor license usage through our platform",
        cells: [false, true, true],
      },
      {
        title: "Crash Reports",
        tooltip:
          "Streamline your customer support by retrieving crash reports within your platform and associating this information to the device and the licensed application that caused it",
        cells: [false, false, true],
      },
      {
        title: "Surveys",
        tooltip:
          "Add feedback forms in your application and retrieve this information within the LicenseSpring platform",
        cells: [false, false, true],
      },
      {
        title: "Source Tracking",
        tooltip:
          "Help your sales and marketing teams close more business by tracking how your end users found your application and associate this with the application usage.",
        cells: [false, false, true],
      },
    ],
  },
  {
    headline: "Account Management",

    rows: [
      {
        title: "User Roles: Admin",
        cells: [true, true, true],
      },
      {
        title: "User Roles: Developer",
        cells: [false, true, true],
      },
      {
        title: "User Roles: Support",
        cells: [false, true, true],
      },
      {
        title: "User Roles: Marketing",
        cells: [false, true, true],
      },
      {
        title: "User Roles: Billing",
        cells: [false, true, true],
      },
      {
        title: "Sub Accounts and roles",
        tooltip:
          "Create Sub Accounts, and allow sub account administer to assign the licenses to the end-users",
        cells: [false, false, true],
      },
      {
        title: "Geo-Server Restriction",
        tooltip:
          "Specify the continents or specific countries that an instance of our LicenseSpring cloud cn run on",
        cells: [false, false, true],
      },
    ],
  },
  {
    headline: "Support",

    rows: [
      {
        title: "Uptime",
        cells: ["99.9%", "99.9% SLA", "99.9% SLA"],
      },
      {
        title: "Support type",
        cells: [
          "email",
          "email, replies within 2 business days",
          "email, phone, within 1 business day",
        ],
      },
    ],
  },
  {
    headline: "Pricing",
    pricing: true,
    cells: ["Free", "Standard", "Enterprise"],

    rows: [
      {
        title: "Monthly Price",
        cells: ["-", "$99", "Contact Us"],
      },
      {
        title: "Yearly Price",
        tooltip: "2 months free on our Standard offer",
        cells: ["-", "$990", "Contact Us"],
      },
    ],
  },
]
