import React from "react"
import PricingPdf from "../../../../assets/pdf/LicenseSpring_pricing_table.pdf"
import scrollTo from "gatsby-plugin-smoothscroll"
import Arrow from "../../../../assets/img/arrow.svg"
import Button from "../../../../components/Button"
import Info from "../../../../assets/img/info.svg"
import "./tiers.scss"

export default () => (
  <section className="pricingPage__tiers row">
    <div className="tier col__md-30">
      <h2>Free</h2>
      <p>
        For Solo Developers, start-ups, and companies with early access programs
        who are looking for basic licensing for their projects
      </p>
      <h3>0</h3>
      <Button to="/get-started/" color="blue" label="Start with free" />
      <ul>
        <li>
          <span>5,000</span> monthly requests
        </li>
        <li>
          <span>3</span> products
        </li>
        <li>
          <span>1</span> development environment
        </li>
        <li>Node-Locking</li>
        <li>Key-Based</li>
      </ul>
    </div>
    <div className="tier col__md-30">
      <h2>Standard</h2>
      <p>
        For ISVs, Game Developers, and organizations looking to protect their
        business with standard licensing models
      </p>
      <h3>99</h3>
      <Button to="/get-started/" color="blue" label="Open trial account" />
      <ul>
        <li>
          <span>100,000</span> monthly requests
        </li>
        <li>
          <span>50</span> products
        </li>
        <li>
          <span>All</span> development environments
        </li>
        <li>Node-Locking</li>
        <li>Key or User-Based</li>
        <li>Offline Licensing</li>
        <li>Product Versioning</li>
      </ul>
    </div>
    <div className="tier col__md-30">
      <h2>Enterprise</h2>
      <p>
        For projects used by a large audience, or for organizations with
        sophisticated licensing requirements
      </p>
      <h3 className="pricingPage__priceless">
        Please contact us <br />
        regarding the price
      </h3>
      <Button to="/get-started/" color="blue" label="Open trial account" />
      <ul>
        <li>
          <span>Unlimited</span> monthly requests
        </li>
        <li>
          <span>Unlimited</span> products
        </li>
        <li>
          <span>All</span> development environments
        </li>
        <li>Node-Locking</li>
        <li>Key or User-Based</li>
        <li>Offline Licensing</li>
        <li>Product Versioning</li>
        <li>Unlimited MAUs</li>
        <li>Custom Authentication</li>
        <li>Floating Licenses</li>
        <li>White Label APIs</li>
      </ul>
    </div>
    <a
      href={PricingPdf}
      target="_blank"
      rel="noopener noreferrer"
      className="pricingPage__button pricingPage__button--download"
    >
      <img src={Info} alt="PDF icon" />
      Download full pricing brochure
    </a>

    <button
      onClick={() => scrollTo("#plans")}
      className="pricingPage__button pricingPage__button--plans"
    >
      See detailed plans overview <img src={Arrow} alt="Arrow icon" />
    </button>
  </section>
)
